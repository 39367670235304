















































import { Component, Vue } from "vue-property-decorator";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { gustos_platosDto } from "@/shared/dtos/visualizacion_dieta/entrevista/gustos_platosDto";
import { UtilsString } from "@/utils/utils-string";
import { UtilsEncript } from "@/utils/utils-encript";
import gustosModule from "@/store/modules/gustos-paciente-module";
import { ssmMessageService } from "@/shared/services/message-service";
import { gustosGlobalesDto } from "@/shared/dtos/visualizacion_dieta/entrevista/gustosGlobalesDto";
@Component({
  components: {
    TableSelect: () => import("@/components/DataTableSelect/TableSelect.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
  },
})
export default class CompGustosPaciente extends Vue {
  public tab: any = null;
  public id_paciente: number = 0;
  public alimentos_nogustan: gustos_platosDto[] = [];
  public platos_nogustan: gustos_platosDto[] = [];

  public async created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
    }
    await nueva_platoModule.getall_platos_gustos_paciente(this.id_paciente);
    await alimentoModule.getall_alimentos_gustos_paciente(this.id_paciente);
    this.platos_nogustan = nueva_platoModule.NoGustan_nueva_plato_gustos;
    this.alimentos_nogustan = alimentoModule.NoGustan_nueva_alimento_gustos;
  }

  public get platos() {
    return nueva_platoModule.All_nueva_plato_gustos;
  }
  public get alimentos() {
    return alimentoModule.All_nueva_alimento_gustos;
  }

  public get headers() {
    return [
      {
        text: "Nombre",
        value: "nombre",
        width: "50%",
      },
      { text: "Grupo", value: "grupo", width: "50%" },
    ];
  }

  public get tolheight() {
    return this.$vuetify.breakpoint.height - 412;
  }

  public Aceptar() {
    let data = new gustosGlobalesDto();
    data.id_paciente = this.id_paciente;
    data.NoGustan_nueva_plato_gustos = this.platos_nogustan;
    data.NoGustan_nueva_alimentos_gustos = this.alimentos_nogustan;
    gustosModule
      .guardargustos(data)
      .then(() => ssmMessageService.toastsuccesful("Guardado correctamente"));
  }
}
