import { store } from '@/store/store';
import {
    VuexModule,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { gustosGlobalesDto } from '@/shared/dtos/visualizacion_dieta/entrevista/gustosGlobalesDto';

@Module({
    namespaced: true,
    name: 'gustosModule',
    store,
    dynamic: true
})
class gustosModule extends VuexModule {

    @Action
    public async guardargustos(opciones: gustosGlobalesDto) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.gustos_paciente + '/gustos_globales', opciones.toJson());
    }


}
export default getModule(gustosModule);